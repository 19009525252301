<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="left">
      <div fxFlex="15%">
        <mat-icon class="mat-warn-icon">warning</mat-icon>
      </div>
      <div fxFlex="85%">
        <p [innerHTML]="data.text"></p>
      </div>
    </div>
    <ng-container *ngIf="data?.counties?.length">
      <div *ngFor="let state of data?.counties">
        <p class="state-name-position"><strong>{{state.state_name}}</strong></p>
        <div>
          <ul *ngFor="let county of state.counties"class="county-list">
            <li class="list-position">{{county.county_name}}</li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions align="center" *ngIf="data.type != 'pick-role'">
    <button *ngIf="data?.type != 'ok'" mat-raised-button color="warn" (click)="yesDelete()">Yes</button>
    <button mat-raised-button mat-dialog-close>{{data?.type != 'ok' ? 'No' : 'Ok'}}</button>
  </div>
  <div mat-dialog-actions align="center" *ngIf="data.type == 'pick-role'">
    <button mat-raised-button mat-dialog-close color="warn">OK</button>
    <button mat-raised-button mat-dialog-close>CANCEL</button>
  </div>
</div>